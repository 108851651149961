.freestart-dialog-content {
    background: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.freestart-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    /* Ensure it appears on top of everything */
}

/* チュートリアルヘッダー */
.tutorial-head {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
    color: #0073e6;
}

/* ポイントの強調 */
.tutorial-point {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
    color: #555;
    letter-spacing: 1px;
}

/* 各ステップの設定 */
.tutorial-step {
    font-size: 20px;
    font-weight: bold;
    color: #0073e6;
    margin-bottom: 10px;
    text-align: center;
}

/* ステップの詳細テキスト */
.tutorial-step-text {
    font-size: 16px;
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    margin: 0 20px 20px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* リンクやQRコードの説明 */
.tutorial-step-text a {
    color: #0073e6;
    text-decoration: none;
    font-weight: bold;
}

/* スマホ対応 (レスポンシブ) */
@media screen and (max-width: 768px) {
    .tutorial-step-text {
        font-size: 14px;
        padding: 10px;
    }

    .tutorial-head {
        font-size: 20px;
    }

    .tutorial-step {
        font-size: 18px;
    }
}