*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.tw-fixed {
  position: fixed;
}

.tw-inset-0 {
  inset: 0;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-tw-ml-1 {
  margin-left: -.25rem;
}

.tw-mb-1 {
  margin-bottom: .25rem;
}

.tw-mb-2 {
  margin-bottom: .5rem;
}

.tw-mb-3 {
  margin-bottom: .75rem;
}

.tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-mb-6 {
  margin-bottom: 1.5rem;
}

.tw-mr-2 {
  margin-right: .5rem;
}

.tw-mr-3 {
  margin-right: .75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-mt-8 {
  margin-top: 2rem;
}

.tw-block {
  display: block;
}

.tw-flex {
  display: flex;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-min-h-screen {
  min-height: 100vh;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-full {
  width: 100%;
}

.tw-max-w-md {
  max-width: 28rem;
}

.tw-max-w-sm {
  max-width: 24rem;
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg);
  }
}

.tw-animate-spin {
  animation: 1s linear infinite tw-spin;
}

.tw-cursor-not-allowed {
  cursor: not-allowed;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-items-center {
  align-items: center;
}

.tw-justify-center {
  justify-content: center;
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-whitespace-pre-line {
  white-space: pre-line;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-lg {
  border-radius: .5rem;
}

.tw-border {
  border-width: 1px;
}

.tw-border-2 {
  border-width: 2px;
}

.tw-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.tw-border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

.tw-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.tw-bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.tw-bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.tw-bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.tw-bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.tw-bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.tw-p-2 {
  padding: .5rem;
}

.tw-p-3 {
  padding: .75rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-6 {
  padding: 1.5rem;
}

.tw-p-8 {
  padding: 2rem;
}

.tw-px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.tw-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tw-py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.tw-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-text-center {
  text-align: center;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.tw-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.tw-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.tw-text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.tw-text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-opacity-25 {
  opacity: .25;
}

.tw-opacity-75 {
  opacity: .75;
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:tw-bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:tw-bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.hover\:tw-underline:hover {
  text-decoration-line: underline;
}

.focus\:tw-border-transparent:focus {
  border-color: #0000;
}

.focus\:tw-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:tw-ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

@media (width >= 640px) {
  .sm\:tw-p-8 {
    padding: 2rem;
  }

  .sm\:tw-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f4f4f4;
  height: 100vh;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.container {
  flex-direction: column;
  height: 100vh;
  display: flex;
}

.canceled-container {
  filter: blur(5px);
}

.header {
  z-index: 1000;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.logo {
  height: 100%;
}

.hamburger {
  display: none;
}

.sidebar {
  background-color: #fff;
  border-right: 1px solid #ddd;
  flex-direction: column;
  width: 250px;
  height: calc(100vh - 60px);
  padding-top: 20px;
  transition: transform .3s;
  display: flex;
  position: fixed;
  top: 60px;
  left: 0;
  transform: translateX(0);
}

.sidebar p {
  color: #333;
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 16px;
}

.menu-buttons {
  width: 100%;
}

.font-red {
  color: red;
}

.menu-button {
  text-align: left;
  color: #333;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 15px 20px;
  font-size: 14px;
  transition: background .3s;
}

.menu-button:disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #ccc;
}

.menu-button:hover {
  background-color: #f0f0f0;
}

.logout-button {
  color: red;
}

.main-content {
  height: calc(100vh - 60px);
  margin-left: 250px;
  padding: 80px 20px 20px;
  transition: margin-left .3s;
  overflow-y: auto;
}

p.paidchange {
  color: #00f;
  cursor: pointer;
  text-decoration: underline;
}

p.paidchange:hover {
  color: #00008b;
}

@media (width <= 768px) {
  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0;
  }

  .hamburger {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 24px;
    display: block;
  }
}

.content {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 0 10px #0000001a;
}

.logout-dialog {
  z-index: 1001;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.logout-dialog-content {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
}

@media (width <= 768px) {
  .main-content {
    margin-left: 0;
    padding-top: 80px;
  }

  .sidebar {
    width: 200px;
  }

  .menu-button {
    padding: 10px 15px;
  }

  .sidebar.open {
    transform: translateX(0);
  }
}

.form-table input[type="text"], .form-table select {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 50px);
  padding: 8px;
}

.invoice-dl-btn {
  text-align: center;
}

.invoice-dl-btn button {
  color: #fff;
  cursor: pointer;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  padding: 15px 32px;
  font-size: 16px;
  transition: background-color .3s;
}

.invoice-dl-btn button:hover {
  background-color: #45a049;
}

.invoice-dl-btn button:disabled {
  cursor: not-allowed;
  color: #666;
  background-color: #ccc;
}

.form-table input[type="text"]:focus, .form-table select:focus {
  border-color: #009688;
  outline: none;
}

.radio-group {
  align-items: center;
}

.radio-item {
  margin-right: 20px;
  display: inline-block;
}

.radio-item input[type="radio"] {
  width: auto;
  margin-right: 5px;
}

.form-container {
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 8px;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  box-shadow: 0 2px 4px #0000001a;
}

.form-table {
  border-collapse: collapse;
  width: 100%;
}

.form-table td {
  vertical-align: middle;
  padding: 10px 5px;
}

.form-table label {
  color: #333;
  white-space: nowrap;
  margin-right: 5px;
  font-weight: bold;
}

.lefttd {
  text-align: right;
  width: 100px;
  padding-right: 10px;
}

.submit-button {
  color: #fff;
  cursor: pointer;
  float: right;
  background-color: #009688;
  border: none;
  border-radius: 4px;
  align-self: center;
  width: 150px;
  margin-top: 20px;
  margin-right: 50px;
  padding: 10px 20px;
}

.submit-button:hover {
  background-color: #00796b;
}

.qr-code-generator {
  box-sizing: border-box;
  text-align: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  box-shadow: 0 2px 4px #0000001a;
}

.qr-title {
  margin-bottom: 20px;
  font-size: 24px;
}

.qr-content {
  margin-bottom: 20px;
}

.qr-url, .qr-link {
  color: #00f;
  text-decoration: underline;
}

.qr-code-result {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.qr-code {
  margin: 20px 0;
}

.qr-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

.qr-button:hover {
  background-color: #45a049;
}

.subscriber-container {
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 8px;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  box-shadow: 0 2px 4px #0000001a;
}

.payment-card-choice label {
  margin-bottom: 10px;
  display: block;
}

.payment-explan {
  border: 2px solid #000;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 10px;
}

.payment-kiyaku {
  text-align: center;
}

.payment-kiyaku-check {
  width: 15px;
  height: 15px;
  margin-right: 7px;
}

.payment-kiyaku-link {
  color: #00f;
  text-decoration: underline;
}

.payment-price-table {
  padding: 20px 10px;
}

.price-table {
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  font-size: 14px;
}

.price-table th, .price-table td {
  text-align: center;
  border: 1px solid #000;
  padding: 5px;
}

.price-table-header {
  background-color: #f0f0f0;
}

.price-table-row {
  background-color: #fff;
}

.payment-form-container {
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 8px;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  box-shadow: 0 2px 4px #0000001a;
}

.payment-form-container table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
}

.payment-form-container td {
  vertical-align: top;
  border: 1px solid #b6b6b6;
  padding: 10px;
}

.payment-label {
  color: #333;
  background-color: #e0e0e0;
  font-weight: bold;
}

.payment-value {
  color: #1b1b1b;
  background-color: #fff;
  font-weight: bold;
}

.payment-form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}

.payment-input-group {
  width: 100%;
  margin-bottom: 20px;
}

.payment-input-label {
  margin-bottom: 0;
  font-size: 14px;
  display: block;
}

.auto-renew-off {
  color: red;
  font-weight: bold;
}

.payment-form-container.a {
  color: #06c;
  text-decoration: none;
}

.payment-form-container.a:hover {
  text-decoration: underline;
}

.payment-card-element, .payment-input {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  position: relative;
}

.card-number-container {
  position: relative;
}

.card-brand {
  pointer-events: none;
  gap: 5px;
  display: flex;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.brand-logo {
  pointer-events: none;
  width: 30px;
  height: 19px;
}

.payment-submit-button {
  color: #fff;
  cursor: pointer;
  background-color: #e60000;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.payment-submit-button:disabled {
  background-color: #ccc;
}

.payment-error-message {
  color: red;
  margin-top: 10px;
}

.payment-success-message {
  color: green;
  margin-top: 10px;
}

.payment-input-row {
  gap: 16px;
  width: 100%;
  display: flex;
}

.payment-input-group {
  flex: 1;
}

.loading-overlay {
  z-index: 999;
  background-color: #fffc;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.spinner {
  border: 4px solid #0000001a;
  border-left-color: #e60000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.close-popup-button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 40px;
  position: absolute;
  right: 10px;
}

.payment-pop-container {
  background-color: #fff;
  border-radius: 8px;
  width: 400px;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 8px #0003;
}

.popup-overlay {
  z-index: 1000;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.payment-link-button {
  color: #06c;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
  text-decoration: underline;
}

.payment-link-button:hover {
  color: #049;
  text-decoration: underline;
}

.payment-plan {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.stop-dialog {
  z-index: 1001;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.stop-dialog-content {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
}

.tokusyo-tips {
  box-sizing: border-box;
  border: 1px solid #000;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
}

.tokusyo-block {
  margin-bottom: 20px;
}

.tokusyo-head {
  margin-bottom: 10px;
  font-weight: bold;
}

.tokusyo-block p {
  margin: 5px 0;
}

.alert {
  color: #fff;
  z-index: 1000;
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  transition: opacity .5s ease-out, transform .5s ease-out;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.alert.success {
  background-color: #4caf50;
}

.alert.error {
  background-color: #f44336;
}

.fade-in {
  opacity: 1;
  transform: translateX(-50%)translateY(0);
}

.fade-out {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%)translateY(-20px);
}

.contact-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 4px #0000001a;
}

.contact-header {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.contact-form {
  flex-direction: column;
  display: flex;
}

.contact-label {
  color: #555;
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-select, .contact-textarea {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.contact-textarea {
  height: 250px;
}

.contact-select {
  background-color: #fff;
}

.contact-textarea:disabled {
  background-color: #e0e0e0;
}

.contact-submit-button {
  color: #fff;
  cursor: pointer;
  background-color: #009688;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.contact-submit-button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.contact-submit-button:hover:not(:disabled) {
  background-color: #00796b;
}

.delete-dialog {
  z-index: 1001;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.delete-dialog-content {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
}

.btn-confirm, .btn-cancel {
  cursor: pointer;
  border: none;
  margin: 10px;
  padding: 10px 20px;
}

.btn-confirm {
  color: #fff;
  background-color: red;
}

.btn-cancel {
  color: #fff;
  background-color: gray;
}

.confirm-dialog {
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.link {
  color: #00f;
  text-decoration: underline;
}

.confirm-dialog-content {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.ready-container {
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px #0000001a;
}

.ready-container h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.ready-review-create p {
  color: #555;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.5;
}

.ready-notice {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 20px;
}

.ready-notice p {
  color: #666;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.6;
}

@media screen and (width <= 800px) {
  .ready-container {
    padding: 10px;
  }

  .ready-container h2 {
    font-size: 20px;
  }

  .ready-review-create p, .ready-notice p {
    font-size: 14px;
  }
}

.home-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 10px;
}

.home-review-box {
  border: 1px solid #ccc;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
}

.review-counts {
  flex: 1;
}

.home-line-chart-container {
  flex: 2;
  width: 100%;
  height: 100%;
  max-height: 300px;
}

canvas {
  max-width: 100%;
  max-height: 250px;
}

.home-answer-section {
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
}

.home-answer-box {
  text-align: center;
  border: 1px solid #ccc;
  width: 30%;
  padding: 20px;
}

.home-answer-text {
  text-align: center;
  width: 30%;
  padding: 20px;
}

.home-comments-header {
  margin-bottom: 10px;
}

.home-comments-box {
  flex-direction: column;
  width: 45%;
  display: flex;
}

.home-comments-container {
  justify-content: space-between;
  gap: 20px;
  display: flex;
}

.home-spam-comments, .home-user-comments {
  border: 1px solid #ccc;
  width: 100%;
  height: 200px;
  padding: 20px;
  overflow-y: auto;
}

.home-heading {
  margin-bottom: 10px;
}

.home-subheading {
  margin-bottom: 10px;
  font-size: 14px;
}

.home-comment-list {
  padding: 0;
  list-style-type: none;
}

.home-comment-item {
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  font-size: 12px;
}

.freestart-dialog-content {
  text-align: center;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 20px;
}

.freestart-dialog {
  z-index: 1001;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.tutorial-head {
  text-align: center;
  color: #0073e6;
  margin: 20px 0;
  font-size: 24px;
  font-weight: bold;
}

.tutorial-point {
  text-align: center;
  color: #555;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-size: 18px;
}

.tutorial-step {
  color: #0073e6;
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.tutorial-step-text {
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 20px 20px;
  padding: 15px;
  font-size: 16px;
  box-shadow: 0 4px 8px #0000001a;
}

.tutorial-step-text a {
  color: #0073e6;
  font-weight: bold;
  text-decoration: none;
}

@media screen and (width <= 768px) {
  .tutorial-step-text {
    padding: 10px;
    font-size: 14px;
  }

  .tutorial-head {
    font-size: 20px;
  }

  .tutorial-step {
    font-size: 18px;
  }
}

.change-password-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 4px #0000001a;
}

.change-password-header {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.change-password-form {
  flex-direction: column;
  display: flex;
}

.change-password-label {
  color: #555;
  margin-bottom: 10px;
  font-weight: bold;
}

.change-password-input {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.change-password-submit-button {
  color: #fff;
  cursor: pointer;
  background-color: #009688;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.change-password-submit-button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.change-password-submit-button:hover:not(:disabled) {
  background-color: #00796b;
}

.pw-error-message {
  color: red;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

.payment-notice {
  background-color: #f9f9f9;
  border: 2px solid #ccc;
  border-radius: 10px;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
}

.payment-notice a {
  color: #00f;
  text-decoration: underline;
}

.payment-notice:before {
  content: "🔔";
  margin-right: 10px;
  font-size: 22px;
}

.spinner {
  border: 4px solid #0000001a;
  border-left-color: #09f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  animation: 1s infinite spin;
}

.loading-overlay {
  z-index: 9999;
  background: #fffc;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}
/*# sourceMappingURL=index.e3fc960e.css.map */
