.subscriber-container{
  max-width: 800px;
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    width: 100%;
    padding: 20px; 
    box-sizing: border-box;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.payment-card-choice label {
  display: block;
  margin-bottom: 10px; /* ラジオボタン同士の間隔を調整する */
}


.payment-explan {
  border: 2px solid #000;
  /* 枠線を黒色に設定 */
  padding: 10px;
  /* 内側の余白を設定 */
  border-radius: 10px;
  /* 枠線に丸みを持たせる */
  margin-top: 10px;
    /* 上に20pxの余白を追加 */
    margin-bottom: 30px;
    /* 下に20pxの余白を追加 */
}

.payment-kiyaku{
  text-align: center;
}

.payment-kiyaku-check{
      width: 15px;
      height: 15px;
      margin-right: 7px; /* 横幅にスペースを追加 */
}

.payment-kiyaku-link {
  color: blue;
  /* 青文字にする */
  text-decoration: underline;
  /* 下線をつける */
}

.payment-price-table {
  padding: 20px 10px; /* 上下20px、左右10pxの余白 */
 
}

.price-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  background-color: white;
}

.price-table th,
.price-table td {
  border: 1px solid black;
  text-align: center;
  padding: 5px;
}

.price-table-header {
  background-color: #f0f0f0;
}

.price-table-row {
  background-color: #ffffff;
}

.payment-form-container {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.payment-form-container table {
  width: 100%;
    /* テーブルを親要素の幅いっぱいに広げる */
    border-collapse: separate;
    /* border-radius を効かせるために collapse ではなく separate を使用 */
    border-spacing: 0;
    /* セルの間隔を 0 にして、連続性を持たせる */
    border-radius: 5px;
    /* テーブル全体に角丸を適用 */
    overflow: hidden;
    /* 角丸の中にセルが収まるようにする */
}

.payment-form-container td {
  padding: 10px;
  vertical-align: top;
  border: 1px solid #b6b6b6;
  /* Table border for row/column separation */
}

.payment-label {
    font-weight: bold;
    background-color: #e0e0e0;
    /* Darker background color for label */
    color: #333;
}

.payment-value {
    background-color: #ffffff;
    /* Lighter background color for value */
    color: #1b1b1b;
    font-weight: bold;
}

.payment-form-title {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.payment-input-group {
  margin-bottom: 20px;
  width: 100%;
  /* すべてのフィールドの幅を統一 */
}

.payment-input-label {
  display: block;
  font-size: 14px;
  margin-bottom: 0px;
}

.auto-renew-off {
  color: red;
  font-weight: bold;
}

.payment-form-container.a {
  color: #0066cc;
  text-decoration: none;
}

.payment-form-container.a:hover {
  text-decoration: underline;
}

.payment-card-element,
.payment-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
}

.card-number-container {
  position: relative;
}

.card-brand {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* ロゴを触れないようにする */
  display: flex;
  gap: 5px;
}

.brand-logo {
  width: 30px;
  height: 19px;
  pointer-events: none;
  /* ロゴを触れないようにする */
}

.payment-submit-button {
  width: 100%;
  padding: 10px;
  background-color: #e60000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}



.payment-submit-button:disabled {
  background-color: #ccc;
}

.payment-error-message {
  color: red;
  margin-top: 10px;
}

.payment-success-message {
  color: green;
  margin-top: 10px;
}

.payment-input-row {
  display: flex;
  gap: 16px;
  width: 100%;
}

.payment-input-group {
  flex: 1;
  /* 各フィールドが均等の幅を取る */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #e60000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* ポップアップを閉じるボタン */
.close-popup-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}

/* ポップアップのコンテナ */
.payment-pop-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* オーバーレイ */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 背景を半透明にする */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* 最前面に表示 */
}

/* button を a タグ風にスタイル */
.payment-link-button {
  background: none;
  border: none;
  padding: 0;
  color: #0066cc;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1em;
  font-family: inherit;
}


.payment-link-button:hover {
  color: #004499;
  text-decoration: underline;
}

.payment-plan {
  display: flex;
  justify-content: space-between;
  /* 左右に配置 */
  align-items: center;
  /* 垂直方向の中央揃え (必要に応じて) */
}

/* サブスク停止ダイアログ */
.stop-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    /* Ensure it appears on top of everything */
}

.stop-dialog-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.btn-confirm,
.btn-cancel {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    cursor: pointer;
}

.btn-confirm {
    background-color: red;
    color: white;
}

.btn-cancel {
    background-color: gray;
    color: white;
}

.tokusyo-tips {
  border: 1px solid black;
  /* 黒い枠で囲む */
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;
}

.tokusyo-block {
  margin-bottom: 20px;
  /* liごとに間隔を設ける */
}

.tokusyo-head {
  font-weight: bold;
  /* 文字を太くする */
  margin-bottom: 10px;
}

.tokusyo-block p {
  margin: 5px 0;
}