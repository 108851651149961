.qr-code-generator {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    text-align:center;
  }
  
  .qr-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .qr-content {
    margin-bottom: 20px;
  }
  
  .qr-url, .qr-link {
    color: blue;
    text-decoration: underline;
  }
  
  .qr-code-result {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .qr-code {
    margin: 20px 0;
  }
  
  .qr-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .qr-button:hover {
    background-color: #45a049;
  }
  