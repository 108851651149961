/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    height: 100vh;
    overflow: hidden;
    /* Prevent page overflow */
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/* サブスク停止後のコンテナ */
.canceled-container{
    filter: blur(5px); /* 5px はブラーの強さ、必要に応じて変更してください */
}

/* Header Section */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    height: 60px;
    /* Adjust header height if needed */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    /* Ensure header is always on top */
}

.logo {
    height: 100%;
}

/* Hide the hamburger by default (for larger screens) */
.hamburger {
    display: none;
}

/* Sidebar Styling */
.sidebar {
    width: 250px;
    height: calc(100vh - 60px);
    /* Sidebar height is full height minus the header height */
    background-color: #fff;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    position: fixed;
    top: 60px;
    /* Starts right below the header */
    left: 0;
    transform: translateX(0);
    /* Sidebar always visible on larger screens */
    transition: transform 0.3s ease;
}

.sidebar p {
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
}

.menu-buttons {
    width: 100%;
}

.font-red {
    color:red;
}

.menu-button {
    width: 100%;
    padding: 15px 20px;
    text-align: left;
    background: none;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: background 0.3s ease;
}

.menu-button:disabled {
    background-color: #cccccc;
    /* 無効時のグレー背景色 */
    color: #666666;
    /* 無効時の文字色 */
    cursor: not-allowed;
    /* マウスカーソルを「禁止」アイコンにする */
} 

.menu-button:hover {
    background-color: #f0f0f0;
}

.logout-button {
    color: red;
}

.main-content {
    margin-left: 250px;
    padding: 80px 20px 20px 20px;
    /* Add top padding to account for the fixed header */
    height: calc(100vh - 60px);
    /* Adjust main content height below the header */
    overflow-y: auto;
    /* Scrollable content area */
    transition: margin-left 0.3s ease;
}

/* 継続利用はこちらから */
p.paidchange {
    color: blue;
    /* リンクの色（通常の青色リンクを模倣） */
    text-decoration: underline;
    /* リンクの下線 */
    cursor: pointer;
    /* マウスホバー時のポインタ */
}

p.paidchange:hover {
    color: darkblue;
    /* ホバー時の色 */
}

/* Sidebar for Mobile */
@media (max-width: 768px) {
    .sidebar {
        transform: translateX(-100%);
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .main-content {
        margin-left: 0;
    }

    /* Show hamburger menu only on mobile */
    .hamburger {
        display: block;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
    }
}

/* Content Area */
.content {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Logout Confirmation Dialog */
.logout-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    /* Ensure it appears on top of everything */
}

.logout-dialog-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.btn-confirm,
.btn-cancel {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    cursor: pointer;
}

.btn-confirm {
    background-color: red;
    color: white;
}

.btn-cancel {
    background-color: gray;
    color: white;
}

/* Responsive Sidebar */
@media (max-width: 768px) {
    .main-content {
        margin-left: 0;
        padding-top: 80px;
        /* Ensure top padding remains for mobile */
    }

    .sidebar {
        width: 200px;
    }

    .menu-button {
        padding: 10px 15px;
    }

    .sidebar.open {
        transform: translateX(0);
    }
}