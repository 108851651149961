.payment-notice {
    border: 2px solid #ccc;
    /* 四角の枠 */
    border-radius: 10px;
    /* 角を丸くする */
    padding: 20px;
    /* 余白を追加 */
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    /* 背景色 */
    max-width: 1000px;
    margin: 0 auto;
}

.payment-notice  a {
    color: blue;
    /* 青文字にする */
    text-decoration: underline;
    /* 下線をつける */
}

.payment-notice::before {
    content: '\1F514';
    /* お知らせアイコン (🔔) */
    font-size: 22px;
    margin-right: 10px;
    /* テキストとの間に余白を追加 */
}
