.alert {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.alert.success {
  background-color: #4caf50; /* 成功時の緑 */
}

.alert.error {
  background-color: #f44336; /* エラー時の赤 */
}

.fade-in {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.fade-out {
  opacity: 0;
  transform: translateX(-50%) translateY(-20px); /* フェードアウト時に少し上に移動 */
  pointer-events: none; /* フェードアウト時にクリックを無効化 */
}
