.home-container {
    padding: 30px 10px;
    margin: 0 auto;
    max-width: 1000px;
}

.home-review-box {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center; /* 中央揃え（縦方向） */
    justify-content: space-between; /* 必要に応じて間隔を調整 */
}

.review-counts {
    flex: 1; /* これでreview-countsが左側に */
}

/* グラフの親要素に高さ制限を適用 */
.home-line-chart-container {
    width: 100%;
    height: 100%;
    max-height: 300px;
    /* 高さの最大制限 */
    flex: 2;
}

canvas {
    max-width: 100%;
    /* グラフが親要素にフィットするように設定 */
    max-height: 250px;
    /* 高さの制限 */
}

.home-answer-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.home-answer-box {
    width: 30%;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
}

.home-answer-text {
    width: 30%;
    padding: 20px;
    text-align: center;
}

.home-comments-header {
    margin-bottom: 10px;
}

.home-comments-box {
    display: flex;
    flex-direction: column;
    /* ヘッダとコメントを縦に並べる */
    width: 45%;
    /* 横に並べる2つのボックスの幅を調整 */
}

.home-comments-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
        /* ボックス間のスペースを追加 */
}

.home-spam-comments,
.home-user-comments {
    width: 100%;
    border: 1px solid #ccc;
    padding: 20px;
    height: 200px;
    /* コメントセクションの高さを制限 */
    overflow-y: auto;
    /* コメントリストをスクロール */
}

.home-heading {
    margin-bottom: 10px;
}

.home-subheading {
    margin-bottom: 10px;
    font-size: 14px;
}

.home-comment-list {
    list-style-type: none;
    padding: 0;
}

.home-comment-item {
    font-size: 12px;
    border-bottom: 1px solid #ccc; /* 薄いグレーの線 */
    padding: 5px 0; /* 上下に余白を追加 */
}