.change-password-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
}


.change-password-header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.change-password-form {
    display: flex;
    flex-direction: column;
}

.change-password-label {
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
}

.change-password-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    font-family: 'Arial', sans-serif;
}

.change-password-submit-button{
    background-color: #009688;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
}

.change-password-submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.change-password-submit-button:hover:not(:disabled) {
    background-color: #00796b;
}


.pw-error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  