.contact-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
}

.contact-header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-label {
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
}

.contact-select,
.contact-textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    font-family: 'Arial', sans-serif;
}

.contact-textarea{
    height: 250px;
}

.contact-select {
    background-color: #fff;
}

.contact-textarea:disabled {
    background-color: #e0e0e0;
}

.contact-submit-button {
    background-color: #009688;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
}

.contact-submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.contact-submit-button:hover:not(:disabled) {
    background-color: #00796b;
}


/* 退会ボタン Confirmation Dialog */
.delete-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    /* Ensure it appears on top of everything */
}

.delete-dialog-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.btn-confirm,
.btn-cancel {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    cursor: pointer;
}

.btn-confirm {
    background-color: red;
    color: white;
}

.btn-cancel {
    background-color: gray;
    color: white;
}