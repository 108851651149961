/* テキスト入力フィールドに対するスタイル */
.form-table input[type="text"],
.form-table select {
    width: calc(100% - 50px); /* ラベルの幅を考慮してinputの幅を調整 */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.invoice-dl-btn {
  text-align: center; /* 横方向の中央 */
}

.invoice-dl-btn button {
  background-color: #4CAF50; /* ボタンの背景色 */
  color: white; /* 文字色 */
  padding: 15px 32px; /* パディング（ボタンの内側の余白） */
  font-size: 16px; /* フォントサイズ */
  border: none; /* ボーダー無し */
  border-radius: 4px; /* ボタンの角を丸く */
  cursor: pointer; /* ホバー時にポインターを表示 */
  transition: background-color 0.3s ease; /* 背景色の変化にアニメーションを追加 */
}

.invoice-dl-btn button:hover {
  background-color: #45a049; /* ホバー時の背景色 */
}

.invoice-dl-btn button:disabled {
  background-color: #ccc; /* 無効化された時のグレー背景 */
  cursor: not-allowed; /* 無効化された時のカーソル */
  color: #666; /* 無効化された時の文字色 */
}


.form-table input[type="text"]:focus,
.form-table select:focus {
    border-color: #009688;
    outline: none;
}

/* ラジオボタンに対するスタイル */
.radio-group {
    align-items: center;
}
  
.radio-item {
    display: inline-block;
    margin-right: 20px;
}
  
.radio-item input[type="radio"] {
    margin-right: 5px;
    width: auto; /* ラジオボタンの幅は自動調整 */
}

.form-container {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.form-table {
    width: 100%;
    border-collapse: collapse;
}

.form-table td {
    padding: 10px 5px;
    vertical-align: middle;
}

.form-table label {
    font-weight: bold;
    color: #333;
    margin-right: 5px;
    white-space: nowrap;
}

.lefttd {
    width: 100px;
    text-align: right;
    padding-right: 10px;
}

.submit-button {
    background-color: #009688;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
    margin-top: 20px;
    width: 150px;
    float: right;
    margin-right: 50px;
}

.submit-button:hover {
    background-color: #00796b;
}
