.ready-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ready-container h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.ready-review-create p {
    font-size: 18px;
    color: #555;
    line-height: 1.5;
    margin-bottom: 20px;
}

.ready-notice {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.ready-notice p {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
    .ready-container {
        padding: 10px;
    }

    .ready-container h2 {
        font-size: 20px;
    }

    .ready-review-create p, .ready-notice p {
        font-size: 14px;
    }
}
